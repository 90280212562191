// App & Components
import Vue from "vue";
import Aquarius from "@/Aquarius.vue";

/* Plugins */
import vuetify from "./plugins/vuetify.js";
import "./plugins/icons.js";
import VueSweetalert2 from "vue-sweetalert2";
import ActionCableVue from "actioncable-vue";
import PortalVue from "portal-vue";

/* App modules */
import { router, routes } from "./router";
import Store from "./store";
import { i18n } from "./i18n";
import "./plugins/vee-validate.js";

import "@/main.scss";
import "@/mainPrinting.scss";
import { URL as WebsocketURL } from "@/services/Websocket.js";

const actionCableVueOptions = {
  debug: true,
  debugLevel: "all",
  connectionUrl: WebsocketURL,
  connectImmediately: false,
};

Vue.prototype.$routes = routes;
Vue.config.productionTip = false;
Vue.prototype.$formatter = {
  asCurrency(number) {
    if (number) {
      if (number >= 0) {
        return `$${parseFloat(number).
          toFixed(2).
          toString().
          replace(/(\d)(?=(\d{3})+(?!\d))/gu, "$1,")}`;
      }

      return `-$${Math.abs(number).
        toFixed(2).
        toString().
        replace(/(\d)(?=(\d{3})+(?!\d))/gu, "$1,")}`;
    }

    return "$0.00";
  },
};

//Plugins
const IsNumber = {
  install(Vue, _options) {
    Vue.prototype.isNumber = (evt) => {
      evt = (evt) ?
        evt :
        window.event;
      var charCode = (evt.which) ?
        evt.which :
        evt.keyCode;
      if (charCode > 47 && charCode < 58) {
        return true;
      }
      evt.preventDefault();
    };
  },
};
Vue.use(IsNumber);
Vue.use(VueSweetalert2);
Vue.use(ActionCableVue, actionCableVueOptions);
Vue.use(PortalVue);
//Plugins end

Vue.mixin({
  methods: {
    connectWebsocket({ token }) {
      if (this.$cable._cable) {
        this.$cable._cable._url = `${WebsocketURL}?token=${token}`;
      }
      this.$cable._connectionUrl = `${WebsocketURL}?token=${token}`;
      this.$cable.connection.connect(`${WebsocketURL}?token=${token}`);
    },
    disconnectWebsocket() {
      this.$cable.connection.disconnect();
    },
  },
});

new Vue({
  render: (h) => h(Aquarius),
  router,
  store: Store,
  vuetify,
  i18n,
}).$mount("#aquarius");
