import { ValidationProvider, extend } from "vee-validate";
import Vue from "vue";
// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
import { i18n } from "@/i18n";
import { configure } from "vee-validate";
import { required, confirmed, required_if, between, numeric, min, length, max, max_value, double, min_value } from "vee-validate/dist/rules";

// Add imported rules below
extend("required", required);
extend("confirm", confirmed);
extend("required_if", required_if);
extend("between", between);
extend("numeric", numeric);
extend("min", min);
extend("min_value", min_value);
extend("length", length);
extend("not_same", {
  params: ["other"],
  validate: (value, { other }) => {
    return value !== other;
  },
});
extend("max", max);
extend("max_value", max_value)
extend("double", double)

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);

    if (typeof values.target !== "undefined") {
      values.target = i18n.t(`fields.${values.target}`);
    }
    return i18n.t(`validations.messages.${values._rule_}`, values);
  },
});
