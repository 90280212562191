import { SET_BETTING_POOL, SET_LANGUAGE, SET_SESSION_MODE, SET_TOKEN} from "./mutations.js";
import {createSession} from "@/api/session";
import {decodeToken} from "@/services/JwtService";
import camelcaseKeys from "camelcase-keys";
import SessionCreateCategory from "@/imported/SessionCreateCategory.json";

const LOGIN_USER = "Login user",
  LOGOUT_USER = "Logout user";

export {LOGIN_USER, LOGOUT_USER};

export default {
  [LOGIN_USER]({commit}, {form: session, category}) {
    return createSession({session, category}).then((response) => {
      const {authToken} = response.data,
        {data, exp} = camelcaseKeys(decodeToken(authToken), {deep: true});

      commit(SET_TOKEN, {token: authToken, exp});
      if (category === SessionCreateCategory.loginAs) {
        commit(SET_SESSION_MODE, {mode: SessionCreateCategory.user});
      } else {
        commit(SET_SESSION_MODE, {mode: category});
      }
      commit(SET_LANGUAGE, {lang: data.language});
      commit(SET_BETTING_POOL, {bettingPool: data.bettingPool});

      return authToken;
    });
  },
  [LOGOUT_USER]({commit}) {
    return new Promise((resolve) => {
      resolve();
    }).finally(() => {
      commit(SET_TOKEN, {token: null, exp: null});
    });
  },
};
