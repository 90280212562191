import { decodeToken } from "../services/JwtService.js";

export default {
  authenticated: (state) => state.token !== null,
  username(state) {
    const token = state.token;

    try {
      return decodeToken(token).data.username;
    } catch (e) {
      return undefined;
    }
  },
  userId(state) {
    const token = state.token;

    try {
      return decodeToken(token).data.userId;
    } catch (e) {
      return undefined;
    }
  },
  language(state) {
    const token = state.token;

    try {
      return decodeToken(token).data.language;
    } catch (e) {
      return state.lang;
    }
  },
  superuser(state) {
    const token = state.token;

    try {
      return decodeToken(token).data.superuser;
    } catch (e) {
      return false;
    }
  },
  bettingPool(state) {
    const token = state.token;

    try {
      return decodeToken(token).data.bettingPool;
    } catch (e) {
      return null;
    }
  },
  privileges(state) {
    const token = state.token;

    try {
      return decodeToken(token).data.privileges;
    } catch (e) {
      return [];
    }
  },
};
