import en from "./en.json";
import es from "./es.json";
import veeValidateEnglishValidations from "vee-validate/dist/locale/en.json";
import veeValidateSpanishValidations from "vee-validate/dist/locale/es.json";
import vuetify_en from "vuetify/es5/locale/en";
import vuetify_es from "vuetify/es5/locale/es";

const enWithValidations = {
		...en,
		$vuetify: {
			...vuetify_en,
			...en.$vuetify,
		},
		validations: {
			...en.validations,
			messages: {
				...veeValidateEnglishValidations.messages,
				...en.validations.messages,
			},
		},
	},
	esWithValidations = {
		...es,
		$vuetify: {
			...vuetify_es,
			...es.$vuetify,
		},
		validations: {
			...es.validations,
			messages: {
				...veeValidateSpanishValidations.messages,
				...es.validations.messages,
			},
		},
	};
export default {
	en: enWithValidations,
	es: esWithValidations,
};
