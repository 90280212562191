import Vue from "vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faLock,
  faBars,
  faChevronDown,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faCheckSquare,
  faPencilAlt,
  faTrash,
  faSortUp,
  faSortDown,
  faPoll,
  faHome,
  faCalendar,
  faStickyNote,
  faSignOutAlt,
  faCog,
  faSave,
  faBan,
  faTimes,
  faEraser,
  faDollarSign,
  faCopy,
  faServer,
  faPaperclip,
  faGlobe,
  faLandmark,
  faStore,
  faDice,
  faCreditCard,
  faCommentAlt,
  faArchive,
  faChartLine,
  faExternalLinkAlt,
  faSignInAlt, faPrint, faStopwatch, faQuestion,
  faClock,
  faCommentDots,
  faWifi
} from "@fortawesome/free-solid-svg-icons";

import { faCircle, faDotCircle, faSquare } from "@fortawesome/free-regular-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faChrome } from "@fortawesome/free-brands-svg-icons";

library.add(
  faUser,
  faLock,
  faBars,
  faChevronDown,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faSquare,
  faCheckSquare,
  faPencilAlt,
  faTrash,
  faSortUp,
  faSortDown,
  faPoll,
  faHome,
  faStickyNote,
  faCalendar,
  faSignOutAlt,
  faCog,
  faSave,
  faBan,
  faCircle,
  faDotCircle,
  faTimes,
  faEraser,
  faDollarSign,
  faCopy,
  faServer,
  faPaperclip,
  faTimesCircle,
  faGlobe,
  faLandmark,
  faStore,
  faDice,
  faClock,
  faCreditCard,
  faCommentAlt,
  faArchive,
  faChartLine,
  faExternalLinkAlt,
  faSignInAlt,
  faPrint,
  faStopwatch,
  faQuestion,
  faChrome,
  faCommentDots,
  faWifi
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
