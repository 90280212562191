import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";
import Language from "@/imported/Language.json";
import SessionCreateCategory from "@/imported/SessionCreateCategory.json";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["token", "lang", "bettingPool", "sessionMode", "session"],
    }),
  ],
  modules: {},
  state: {
    token: null,
    loadingContent: false,
    lang: Language.spanish,
    bettingPool: null,
    sessionMode: SessionCreateCategory.user,
    session: {
      exp: null
    }
  },
  mutations,
  actions,
  getters,
});
