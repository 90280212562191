import { i18n } from "@/i18n";

const RENEW_TOKEN = "✔️ Renew token with updated 'exp' field.",
  SET_TOKEN = "✔️ Set token.",
  START_LOADING_CONTENT = "✔️ Started loading content.",
  FINISHED_LOADING_CONTENT = "✔️ Finished loading content.",
  SET_LANGUAGE = "✔️ Set language.",
  SET_BETTING_POOL = "✔️ Set betting pool.",
  SET_SESSION_MODE = "✔️ Set session mode.";

export {
  RENEW_TOKEN,
  SET_TOKEN,
  START_LOADING_CONTENT,
  FINISHED_LOADING_CONTENT,
  SET_LANGUAGE,
  SET_BETTING_POOL,
  SET_SESSION_MODE
};

export default {
  [SET_TOKEN](state, { token, exp }) {
    state.token = token;
    state.session.exp = exp * 1000;
  },
  [RENEW_TOKEN](state, { token, exp }) {
    state.token = token;
    state.session.exp = exp * 1000;
  },
  [SET_SESSION_MODE](state, { mode }) {
    state.sessionMode = mode;
  },
  [START_LOADING_CONTENT](state) {
    state.loadingContent = true;
  },
  [FINISHED_LOADING_CONTENT](state) {
    state.loadingContent = false;
  },
  [SET_LANGUAGE](state, { lang }) {
    state.lang = lang;
    i18n.locale = lang;
  },
  [SET_BETTING_POOL](state, { bettingPool }) {
    state.bettingPool = bettingPool;
  },
};
