import Vue from "vue";
import VueRouter from "vue-router";
import Routes from "./routes.js";
import Store from "../store";

import SessionCreateCategory from "@/imported/SessionCreateCategory.json";

Vue.use(VueRouter);

function parseId(route) {
  return {
    id: parseInt(route.params.id, 10),
  };
}

const router = new VueRouter({
  mode: "hash",
  routes: [
    //Regular Layout
    {
      path: "/",
      component: () => import("../components/layout/AdministrationLayout.vue"),
      meta: {
        authenticated: true,
      },
      redirect: Routes.home.dashboardRoute.path,
      //Regular routes
      children: [
        {
          ...Routes.home.dashboardRoute,
          component: () => import("../pages/home/Dashboard.vue"),
        },
        //Users
        {
          ...Routes.user.indexRoute,
          component: () => import("../pages/user/UserIndex.vue"),
        },
        {
          ...Routes.user.globalIndexRoute,
          component: () => import("../pages/user/UserIndex.vue"),
          props: {
            global: true
          }
        },
        {
          ...Routes.user.createRoute,
          component: () => import("../pages/user/UserCreateOrUpdate.vue"),
        },
        {
          ...Routes.user.editRoute,
          component: () => import("../pages/user/UserCreateOrUpdate.vue"),
          props: parseId,
        },
        //Groups
        {
          ...Routes.group.indexRoute,
          component: () => import("../pages/group/GroupIndex.vue"),
        },
        {
          ...Routes.group.createRoute,
          component: () => import("../pages/group/GroupCreateOrUpdate.vue"),
        },
        {
          ...Routes.group.editRoute,
          component: () => import("../pages/group/GroupCreateOrUpdate.vue"),
          props: parseId,
        },
        {
          ...Routes.domain.indexRoute,
          component: () => import("../pages/domain/DomainIndex.vue"),
        },
        {
          ...Routes.domain.createRoute,
          component: () => import("../pages/domain/DomainCreate.vue"),
        },
        {
          ...Routes.domain.editRoute,
          component: () => import("../pages/domain/DomainUpdate.vue"),
          props: parseId,
        },
        //Zones
        {
          ...Routes.zone.indexRoute,
          component: () => import("../pages/zone/ZoneIndex.vue"),
        },
        {
          ...Routes.zone.createRoute,
          component: () => import("../pages/zone/ZoneCreateOrUpdate.vue"),
        },
        {
          ...Routes.zone.editRoute,
          component: () => import("../pages/zone/ZoneCreateOrUpdate.vue"),
          props: parseId,
        },
        {
          ...Routes.zone.manageRoute,
          component: () => import("../pages/zone/ZoneManage.vue"),
        },
        //AccountableEntities
        {
          ...Routes.accountableEntity.indexRoute,
          component: () => import("../pages/accountableEntity/AccountableEntityIndex.vue"),
        },
        {
          ...Routes.accountableEntity.createRoute,
          component: () => import("../pages/accountableEntity/AccountableEntityCreateOrUpdate.vue"),
        },
        {
          ...Routes.accountableEntity.editRoute,
          component: () => import("../pages/accountableEntity/AccountableEntityCreateOrUpdate.vue"),
          props: parseId,
        },
        //AccountableTransactions
        {
          ...Routes.accountableTransaction.indexRoute,
          component: () => import("../pages/accountableTransaction/AccountableTransactionIndex.vue"),
        },
        //AccountableTransactionsGroups
        {
          ...Routes.accountableTransactionsGroup.indexRoute,
          component: () => import("../pages/accountableTransactionsGroup/AccountableTransactionsGroupIndex.vue"),
        },
        //BettingPools
        {
          ...Routes.bettingPool.indexRoute,
          component: () => import("../pages/bettingPool/BettingPoolIndex.vue"),
        },
        {
          ...Routes.bettingPool.createRoute,
          component: () => import("../pages/bettingPool/BettingPoolCreateOrUpdate.vue"),
        },
        {
          ...Routes.bettingPool.editRoute,
          component: () => import("../pages/bettingPool/BettingPoolCreateOrUpdate.vue"),
          props: parseId,
        },
        //Tickets
        {
          ...Routes.ticket.indexRoute,
          component: () => import("../pages/ticket/TicketMonitor.vue"),
          props: { mode: "admin" },
        },
        //Sales
        {
          ...Routes.sale.dailyRoute,
          component: () => import("../pages/sale/Daily.vue")
        },
        {
          ...Routes.sale.weeklyRoute,
          component: () => import("../pages/sale/Weekly.vue")
        },
        {
          ...Routes.sale.historyRoute,
          component: () => import("../pages/sale/History.vue")
        },
        //My Group
        {
          ...Routes.myGroup.configRoute,
          component: () => import("../pages/myGroup/configuration.vue")
        },
        //Report
        {
          ...Routes.report.roundRoute,
          component: () => import("../pages/report/round.vue")
        }
      ],
    },
    //Pool Layout
    {
      path: "/tickets",
      component: () => import("../components/layout/BettingPoolLayout.vue"),
      meta: {
        authenticated: true,
        requiresBettingPool: true,
      },
      redirect: Routes.ticket.createRoute.path,
      //Regular routes
      children: [
        //Tickets
        {
          ...Routes.ticket.createRoute,
          component: () => import("../pages/ticket/TicketCreate.vue"),
          props: true,
        },
      ],
    },
    //Roulette Layout
    {
      path: "/roulette",
      component: () => import("../components/layout/RouletteBoardLayout.vue"),
      meta: {
        authenticated: true,
        requiresBettingPool: true,
        sessionCategory: SessionCreateCategory.roulette
      },
      redirect: Routes.roulette.boardRoute.path,
      //Regular routes
      children: [
        {
          ...Routes.roulette.boardRoute,
          component: () => import("../pages/roulette/Board.vue"),
          props: true,
        },
      ],
    },
    {
      path: "/",
      redirect: Routes.session.createRoute.path,
    },
    {
      ...Routes.session.createRoute,
      component: () => import("../pages/session/SessionCreate.vue"),
    },
    {
      ...Routes.session.rouletteRoute,
      component: () => import("../pages/session/RouletteSessionCreate.vue"),
    },
    {
      ...Routes.others.notFoundRoute,
      component: () => import("../components/NotFoundPage.vue"),
    },
    {
      path: "*",
      redirect: Routes.others.notFoundRoute.path,
    },
  ],
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const matchedRoute = to.matched.find((route) => route.meta.authenticated);
  const isAuthenticated = Store.getters.authenticated;
  const hasBettingPool = Store.getters.bettingPool;
  const isNextSessionRoute = [Routes.session.createRoute.name, Routes.session.rouletteRoute.name].includes(to.name);

  if(matchedRoute && isAuthenticated) { // Authenticated Route && User Authenticated
    const { requiresBettingPool } = matchedRoute.meta;

    if(requiresBettingPool && !hasBettingPool) { //
      return next('/');
    } else if(!requiresBettingPool && hasBettingPool) {
      return next({ name: Routes.ticket.createRoute.name });
    }
  } else if(matchedRoute && !isAuthenticated) { // Authenticated Route && User Unauthenticated
    const { sessionCategory } = matchedRoute.meta;

    if(sessionCategory && sessionCategory === SessionCreateCategory.roulette) {
      return next({ name: Routes.session.rouletteRoute.name });
    }
    return next({ name: Routes.session.createRoute.name });
  } else if(isAuthenticated && isNextSessionRoute) { // User Authenticated && going to session route
    if(hasBettingPool) {
      return to.name === Routes.session.rouletteRoute.name ?
        next({ name: Routes.roulette.boardRoute.name }) : next({ name: Routes.ticket.createRoute.name });
    }
    return next('/');
  }

  return next();
});

export default router;
