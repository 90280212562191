import axios from "axios";
import Store from "../store";
import qs from "qs";

function requestTransformer(data, _headers) {
  if (typeof data !== 'undefined') {
    if (data instanceof FormData) {
      for(let key in data.keys()) {
        const value = data.get(key);
        if(value === null || value === 'null' || value === ""){
          data.delete(key);
        }
      }

      return data;
    }

    Object.keys(data).
      forEach(key => {
        const value = data[key];

        if (value === "" || value === null) {
          Reflect.deleteProperty(data, key);
        } else if (typeof value === "object" && !value instanceof Array) {
          requestTransformer(value, _headers);
        }
      });
  }

  return JSON.stringify(data);
}

const API_VERSION = "v1",
  TOKEN_HEADER_NAME = "x-access-token",
  instance = axios.create({
    baseURL: `${process.env.NODE_ENV === "production" ?
      "https" :
      "http"}://${process.env.VUE_APP_API_URL}/api/${API_VERSION}/`,
    timeout: 5000,
    transformRequest: requestTransformer,
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: "brackets" });
    },
    headers: {
      get: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      post: {
        "Content-Type": "application/json",
      },
      put: {
        "Content-Type": "application/json",
      },
      patch: {
        "Content-Type": "application/json",
      },
    },
  });

instance.interceptors.request.use(
  (config) => {
    if (Store.state.token) {
      config.headers.Authorization = `Bearer ${Store.state.token}`;
    }

    return config;
  },
  (error) => {
    console.error(error);
  },
);

export function attachRequestInterceptor(onSuccess, onFailure) {
  return instance.interceptors.request.use(onSuccess, onFailure);
}

export function detachRequestInterceptor(identifier) {
  return instance.interceptors.request.eject(identifier);
}

export function attachResponseInterceptor(onSuccess, onFailure) {
  return instance.interceptors.response.use((response) => {
    const result = onSuccess(response, response.headers[TOKEN_HEADER_NAME]);

    return result ?? response;
  }, onFailure);
}

export function detachResponseInterceptor(identifier) {
  instance.interceptors.response.eject(identifier);
}

export default instance;
