const Routes = {
  home: {
    dashboardRoute: {
      name: "dashboard-route",
      path: "/dashboard",
    },
  },
  user: {
    createRoute: {
      name: "user-create",
      path: "users/new",
    },
    indexRoute: {
      name: "user-index",
      path: "users",
    },
    globalIndexRoute: {
      name: "global-user-index",
      path: "global-users",
    },
    editRoute: {
      name: "user-edit",
      path: "users/:id/edit",
    },
  },
  group: {
    createRoute: {
      name: "group-create",
      path: "groups/new",
    },
    indexRoute: {
      name: "group-index",
      path: "groups",
    },
    editRoute: {
      name: "group-edit",
      path: "groups/:id/edit",
    },
  },
  domain: {
    createRoute: {
      name: "domain-create",
      path: "domains/new",
    },
    indexRoute: {
      name: "domain-index",
      path: "domains",
    },
    editRoute: {
      name: "domain-edit",
      path: "domains/:id/edit",
    },
  },
  bettingPool: {
    createRoute: {
      name: "betting-pool-create",
      path: "betting-pools/new",
    },
    indexRoute: {
      name: "betting-pool-index",
      path: "betting-pools",
    },
    editRoute: {
      name: "betting-pool-edit",
      path: "betting-pools/:id/edit",
    },
  },
  ticket: {
    createRoute: {
      name: "ticket-create",
      path: "new",
    },
    indexRoute: {
      name: "ticket-monitor",
      path: "monitor",
    },
  },
  zone: {
    createRoute: {
      name: "zone-create",
      path: "zones/new",
    },
    indexRoute: {
      name: "zone-index",
      path: "zones",
    },
    editRoute: {
      name: "zone-edit",
      path: "zones/:id/edit",
    },
    manageRoute: {
      name: "zone-manage",
      path: "zones/manage",
    },
  },
  accountableEntity: {
    createRoute: {
      name: "accountable-entity-create",
      path: "accountable-entities/new",
    },
    indexRoute: {
      name: "accountable-entity-index",
      path: "accountable-entities",
    },
    editRoute: {
      name: "accountable-entity-edit",
      path: "accountable-entities/:id/edit",
    },
  },
  accountableTransaction: {
    indexRoute: {
      name: "accountable-transaction-index",
      path: "accountable-transactions",
    },
  },
  accountableTransactionsGroup: {
    indexRoute: {
      name: "accountable-transactions-group-index",
      path: "accountable-transactions-groups",
    },
  },
  sale: {
    dailyRoute: {
      name: "daily-sales",
      path: "daily-sales"
    },
    weeklyRoute: {
      name: "weekly-sales",
      path: "weekly-sales"
    },
    historyRoute: {
      name: "sales-history",
      path: "sales-history"
    }
  },
  myGroup: {
    configRoute: {
      name: "my-group-config",
      path: "my-group-config"
    }
  },
  report: {
    roundRoute: {
      name: "rounds-daily-report",
      path: "rounds-daily-report"
    }
  },
  roulette: {
    boardRoute: {
      name: "board-route",
      path: "board"
    }
  },
  session: {
    createRoute: {
      name: "session-create-route",
      path: "/sign-in",
    },
    rouletteRoute: {
      name: "session-create-roulette-route",
      path: "/sign-in/roulette"
    }
  },
  others: {
    notFoundRoute: {
      name: "not-found-route",
      path: "/404",
    },
  },
};

export default Routes;
