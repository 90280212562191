<template>
  <div>
    <v-app
      dark>
      <v-main>
        <v-fade-transition
          duration="150"
          mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-main>
    </v-app>
    <div
      id="print-section"
      class="print-section" />
  </div>
</template>

<script>
import { i18n } from "./i18n";

export default {
	name: "Aquarius",
	created() {
		i18n.locale = this.$store.state.lang;
	}
};

</script>
<style lang="scss">

#print-section {
  display: none;
  position: absolute;
  background: white;
  z-index: 9000;
}

</style>
