import httpClient from "./httpClient";
import SessionCreateCategory from "@/imported/SessionCreateCategory.json";

export function createSession({ session, category }) {
  return httpClient.post("/sessions", { session, category });
}

export function checkSession() {
  return httpClient.get("/sessions/check", {
    params: { category: SessionCreateCategory.user },
  });
}

export function checkRouletteSession() {
  return httpClient.get("/sessions/check", {
    params: { category: SessionCreateCategory.roulette },
  });
}

export function logInAsAny(userId) {
  return httpClient.post("/sessions/log-in-as-any", { userId });
}

export function getParams(form) {
  return httpClient.get("/sessions/params", {params: form});
}
